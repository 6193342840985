export const environment = {
    api : {
      domain : 'wbce.ovh.webcapsule.io',
      protocol : 'https',
      port : undefined
    },
    keycloak: {
      domain : 'keycloak.ovh.webcapsule.io',
      protocol : 'https',
      port: undefined
    },
    production: true,
    mock : false,
    directusOnly : false,
    googleTagID : '',
    clarity : '',
    datadog :{
      applicationId: '9140736e-cf2d-415b-8028-918a8c5d1eb1',
      clientToken: 'pub3dcf7d497a84434ba3b5ae265cf72a16',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: 'ovh-app-webcapsule',
      env: 'prod',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 90,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',
  }
};


